:root {
  --desc-height: 0px;
  --desc-height: 0px;
}

.dynamic-height {
  height: calc(100vh - var(--desc-height) - 110px);
}

.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
